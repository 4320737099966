import React, { useState, useEffect, useContext } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails.jsx";

const EventLogItemExtra = (props) => {
  const { event, eventStore, intl } = props;
  const { formatMessage } = intl;
  const [extraDisplay, setExtraDisplay] = useState();
  const ignoreSubTypes = ["drawn"];
  const { sport } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (!ignoreSubTypes.includes(event.subType)) {
      switch (event.eventType) {
        case "foul":
          let filteredEvents = eventStore.filter(
            (ev) =>
              ev.eventType === event.eventType &&
              !ignoreSubTypes.includes(ev.subType) &&
              ev.personId === event.personId &&
              ev.eventTime <= event.eventTime &&
              ev.status !== "deleted"
          );
          if (event.personId) {
            setExtraDisplay(
              <span className={"foul-count foul-" + filteredEvents.length}>
                {filteredEvents.length}
              </span>
            );
          }
          break;
        case "goal":
          if (sport === "handball") {
            const attackTypeKey = event?.options?.attackType
              ? "attackType." + event.options.attackType
              : "attackType.none";
            const attackTypeMessage = "- " + formatMessage({
              id: attackTypeKey,
              defaultMessage: attackTypeKey,
            });

            let failureReasonMessage = "";
            if (event?.options?.failureReason) {
              const failureReasonKey = "failureReason." +
                event.options.failureReason;
              failureReasonMessage = " - " + formatMessage({
                id: failureReasonKey,
                defaultMessage: failureReasonKey,
              });
            }

            setExtraDisplay(
              <span className={"additional-info"}>
              &nbsp;{attackTypeMessage}{failureReasonMessage}
            </span>
            );
          }
          break;
        case "technicalFault":
          if (event?.subType) {
            const technicalFaultKey = "technicalFault." + event.subType;
            const technicalFaultMessage = " - " + formatMessage({
              id: technicalFaultKey,
              defaultMessage: technicalFaultKey,
            });

            setExtraDisplay(
              <span className={"additional-info"}>
                &nbsp;{technicalFaultMessage}
              </span>
            );
          }
          break;
        case "sevenMetrePenalty":
          if (event?.options?.failureReason) {
            const failureReasonKey = "failureReason." +
              event.options.failureReason;
            const failureReasonMessage = "- " + formatMessage({
              id: failureReasonKey,
              defaultMessage: failureReasonKey,
            });

            setExtraDisplay(
              <span className={"additional-info"}>
                &nbsp;{failureReasonMessage}
              </span>
            );
          }
          break;
        default:
          break;
      }
    }

    // eslint-disable-next-line
  }, [event, eventStore]);
  return <>{extraDisplay}</>;
};
export default injectIntl(EventLogItemExtra);
