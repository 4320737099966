import React, { useEffect, useMemo, useState } from "react";

import EventLogPlay from "./EventLogPlay/EventLogPlay";
import EventLogFilter from "./EventLogFilter/EventLogFilter";
import defaultSetup from "../../../Config/defaultSetupConfiguration";
import "./EventLog.scss";

const hiddenEvents = [
  {
    eventType: "videoReview",
    subType: "pending",
  },
  {
    eventType: "suspension",
    options: ["possible"],
  },
  {
    eventType: "yellowCard",
    options: ["possible"],
  },
  {
    eventType: "redCard",
    options: ["possible"],
  },
  {
    eventType: "sevenMetrePenalty",
    options: ["possible"],
  },
];

const EventLog = (props) => {
  const { updateLog, updateState, currentState, eventStore, playStore } = props;
  const { eventToReview } = currentState;
  const [periods, setPeriods] = useState();
  const [filterValues, setFilterValues] = useState({
    period: -1,
    eventType: -1,
    personId: -1,
    flagged: -1,
  });
  const [filterActive, setFilterActive] = useState(false);

  useEffect(() => {
    updateState("updateLog", Math.random());
    // eslint-disable-next-line
  }, [JSON.stringify(currentState.acks)]);

  useEffect(() => {
    if (eventToReview) {
      setFilterValues({
        ...filterValues,
        period: parseInt(eventToReview.metadata.periodId, 10),
      });
    }

    // eslint-disable-next-line
  }, [eventToReview]);

  function setFilter(key, e) {
    let tempFilter = filterValues;
    tempFilter[key] = e.target.value === "-1" ? -1 : e.target.value;
    setFilterValues(tempFilter);
    setFilterActive(true);
    props.forceUpdate();
  }

  function clearFilters() {
    let tempFilter = filterValues;
    Object.keys(filterValues).forEach((key) => {
      tempFilter[key] = -1;
    });
    setFilterValues(tempFilter);
    setFilterActive(false);
    props.forceUpdate();
  }

  function personList() {
    const personsList = [];
    const entities = props.currentState?.entities || [];
    entities.forEach((entity) => {
      entity.persons
        .sort((a, b) => (parseInt(a.bib) < parseInt(b.bib) ? -1 : 1))
        .forEach((person) => {
          personsList.push(person);
        });
    });
    return personsList;
  }

  function isEventHidden(event) {
    return hiddenEvents.some((hiddenEvent) =>
      Object.keys(hiddenEvent).every((key) => {
        if (key === "options") {
          return hiddenEvent["options"].every((opt) => Object.keys(event["options"] ?? {}).includes(opt));
        }
        return hiddenEvent[key] === event[key];
      }),
    );
  }

  const playDisplay = useMemo(() => {
    if (playStore !== null && eventStore !== null) {
      let plays = eventStore;
      if (filterValues.period !== "All") {
        plays = plays.filter(
          (event) =>
            event.periodId ===
            (parseInt(filterValues.period) > -1 ? parseInt(filterValues.period) : currentState.period.periodId),
        );
      }
      plays = plays.filter((event) => event.class === "sport");

      switch (filterValues.eventType) {
        case "scores":
          plays = plays.filter(
            (event) =>
              event.eventType !== "sevenMetrePenalty" && Object.keys(defaultSetup.scoreTypes).includes(event.eventType),
          );
          break;
        case "fouls":
          plays = plays.filter((event) => defaultSetup.foulTypes.includes(event.eventType));
          break;
        case "substitution":
        case "block":
        case "rebound":
        case "steal":
        case "turnover":
        case "sevenMetrePenalty":
          plays = plays.filter((event) => event.eventType === filterValues.eventType);
          break;
        case "sanctions":
          plays = plays.filter((event) => defaultSetup.sanctionTypes.includes(event.eventType));
          break;
        default:
          break;
      }

      if (filterValues.personId !== -1) {
        plays = plays.filter((event) => event.personId === filterValues.personId);
      }

      if (filterValues.flagged !== -1) {
        plays = plays.filter((event) => event.flagged);
      }

      let uniquePlays = [...new Set(plays.map((play) => play.playId))];
      let tempPeriods = [...new Set(playStore.map((play) => play.periodId))];

      plays = playStore.filter((play) => uniquePlays.includes(play.playId)).filter((play) => !!play.playId);

      if (currentState.period && !tempPeriods.includes(currentState.period.periodId)) {
        tempPeriods.unshift(currentState.period.periodId);
      }
      setPeriods(tempPeriods);

      return (
        <React.Fragment>
          {plays
            .sort((a, b) => (new Date(a.eventTime.replace("Z", "")) > new Date(b.eventTime.replace("Z", "")) ? -1 : 1))
            .map((play, index) => {
              let playEvents = eventStore
                .filter((event) => event.playId === play.playId)
                .filter((event) => event.class === "sport")
                .filter((event) => !isEventHidden(event));
              if (playEvents.filter((ev) => ev.status !== "deleted").length > 0) {
                return <EventLogPlay playIndex={index} key={play.playId} play={play} events={playEvents} {...props} />;
              } else {
                return null;
              }
            })}
        </React.Fragment>
      );
    }
    // eslint-disable-next-line
  }, [updateLog, JSON.stringify(filterValues), eventStore, eventToReview]);

  return (
    <React.Fragment>
      <div className={"event-log enabled-" + (currentState.modify && currentState.modify.playId ? "false" : "true")}>
        <EventLogFilter
          periods={periods}
          currentPeriod={currentState.period.periodId}
          setFilter={setFilter}
          clearFilters={clearFilters}
          filterValues={filterValues}
          filterActive={filterActive}
          personList={personList()}
        />
        {playDisplay}
      </div>
    </React.Fragment>
  );
};

export default React.memo(EventLog);
