import React, { useContext, useMemo } from "react";

import EventLogItem from "./EventLogItem";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails.jsx";

const EventLogItems = (props) => {
  const { events, currentState } = props;
  const { sport } = useContext(FixtureDetailsContext);

  const displayEvents = useMemo(() => {
    let playEvents = [];
    playEvents = events.filter((el) => el.status !== "deleted");

    if (
      playEvents &&
      playEvents.length &&
      playEvents[0]
    ) {
      if(playEvents[0].eventType === "substitution") {
        playEvents = playEvents.sort((a, b) => {
          if (a.subType === b.subType) {
            return b.bib - a.bib;
          }
          return a.subType < b.subType ? 1 : -1;
        });
      } else if(["shootOutAttempt", "shootOutDefence"].includes(playEvents[0].eventType)) {
        playEvents = playEvents.sort((a, b) => {
          return new Date(a.eventTime.replace("Z", "")) > new Date(b.eventTime.replace("Z", ""))
            ? -1
            : 1
        });
      }

      if (sport === "handball") {
        const goalEvent = playEvents.find(e => {
          return typeof e?.options?.goalKeeperId === "string";
        });

        if (goalEvent !== undefined) {
          const goalKeeperEntity = currentState.entities.find(e => {
            return e.entityId !== goalEvent.entityId
          })
          playEvents.push({
            eventType: "goalKeeper",
            entityId: goalKeeperEntity.entityId,
            personId: goalEvent.options.goalKeeperId,
            eventId: goalEvent.eventId,
          })
        }
      }
    }

    if (playEvents) {
      return (
        <React.Fragment>
          {playEvents.map((event) => {
            return (
              <EventLogItem event={event} {...props} key={event.eventId + event.subType} />
            );
          })}
        </React.Fragment>
      );
    }
    // eslint-disable-next-line
  }, [events]);

  return <React.Fragment>{displayEvents}</React.Fragment>;
};

export default EventLogItems;
