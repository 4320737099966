export const StaffRoles = {
  coach: { role: "COACH", number: 1 },
  assistantCoach1: { role: "COACH_ASSISTANT", number: 1 },
  assistantCoach2: { role: "COACH_ASSISTANT", number: 2 },
  assistantCoach3: { role: "COACH_ASSISTANT", number: 3 },
  coach_head: { role: "COACH_HEAD", number: 1 },
  coach_assistant: { role: "COACH_ASSISTANT", number: 1 },
  manager: { role: "MANAGER", number: 1 },
  physiotherapist: { role: "PHYSIOTHERAPIST", number: 1 },
  doctor: { role: "DOCTOR", number: 1 },
  other: { role: "OTHER", roles: ["OTHER", "DIRECTOR", "MEDIA_OFFICER", "SECRETARY", "JUDGE_TIMING", "TRAINER"], number: 1 },
};
