export function personsCatchup(message, currentState, updateState) {
  let tempEntities = currentState.entities;
  let parsedMessage = JSON.parse(message);

  // it means it's the global entity (with officials etc) and should be skipped here
  if (parsedMessage.entityId === "FIXTURE") return;

  if (tempEntities) {
    let team = tempEntities.findIndex(
      (team) => parsedMessage.entityId === team.entityId
    );

    let activePlayers = parsedMessage.persons
      ? Object.values(parsedMessage.persons).filter(
          (person) => person.active === true
        )
      : 0;

    Object.keys(parsedMessage.persons).forEach((person) => {
      let personIndex = tempEntities[team].persons.findIndex(
        (tempPerson) => tempPerson.personId === person
      );
      if (personIndex > -1) {
        const parsedPerson = parsedMessage.persons[person];
        if (typeof parsedPerson.active !== "undefined") {
          tempEntities[team].persons[personIndex].active =
            tempEntities[team].persons[personIndex].desiredActiveState ??
            !!parsedPerson.active;
        } else {
          tempEntities[team].persons[personIndex].active =
            tempEntities[team].persons[personIndex].desiredActiveState ?? false;
        }

        tempEntities[team].persons[personIndex].bib =
          parsedMessage.persons[person].bib;
        tempEntities[team].persons[personIndex].captain =
          parsedMessage.persons[person].captain;
        tempEntities[team].persons[personIndex].name =
          parsedMessage.persons[person].name;
        tempEntities[team].persons[personIndex].fullNameLatin =
          parsedMessage.persons[person].name;
        tempEntities[team].persons[personIndex].position =
          parsedMessage.persons[person].position;
        tempEntities[team].persons[personIndex].starter =
          parsedMessage.persons[person].starter;

        tempEntities[team].persons[personIndex].desiredActiveState =
          getDesiredActiveState(
            tempEntities[team].persons[personIndex].active,
            tempEntities[team].persons[personIndex].desiredActiveState
          );
      } else {
        let tempPerson = parsedMessage.persons[person];
        tempPerson.personId = person;
        if (typeof tempPerson.active === "undefined") {
          tempPerson.active = parsedMessage.persons[person].starter;
        }
        tempPerson.fullNameLatin = parsedMessage.persons[person].name;
        tempEntities[team].persons.push(tempPerson);
      }
    });
    tempEntities[team].persons.forEach((player) => {
      let playerIndex = Object.keys(parsedMessage.persons).findIndex(
        (playerKey) => player.personId === playerKey
      );
      if (playerIndex === -1) {
        //remove from entities
        let removeIndex = tempEntities[team].persons.findIndex(
          (removePlayer) => player.personId === removePlayer.personId
        );
        tempEntities[team].persons.splice(removeIndex, 1);
      }
    });

    updateState("entities", tempEntities);
    let mqttState = currentState.mqtt;
    mqttState.entitiesProcessed = true;
    updateState("mqtt", mqttState);
  }
}

function getDesiredActiveState(currentState, desiredState) {
  if (desiredState === undefined) {
    return undefined;
  }

  // still waiting for the persons message
  if (desiredState !== currentState) {
    return desiredState;
  }

  // reset desired state to undefined once the backend is updated
  if (desiredState === currentState) {
    return undefined;
  }
}
