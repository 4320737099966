const ShotOutcomeTypes = ["post", "bar", "save", "off_target"];

export const PenaltyShotFailureReason = {
  name: "penalty-shot-failure",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.outcome-type",
  showOn: [{ state: "primary", value: "penalty-shot-failure", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "shot-outcome-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "singleOption",
        optionName: "failureReason",
        children: ShotOutcomeTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
          ],
          conditions: [
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "failureReason",
              value: "OFF_TARGET",
            },
          ],
        },
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
