import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import EventLogItemExtra from "./EventLogItemExtra";

import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import {
  eventsWithCustomTranslations,
  ignoreSubkeyEventTypes,
  includeSuccessEventTypes,
  includeOptionField,
} from "../../../Base/Utilities/EventHelpers";
import PlayerSelectEditor from "../PlayerSelectEditor/PlayerSelectEditor";
import EventLogDropdown from "../EventLogDropdown/EventLogDropdown";
import { sendEvent } from "../../../Base/Utilities/SendEvent";
import { useCanEditDropdown } from "../../../Base/Hooks/canEditDropdown";
import EventEditDropdown from "../EventEditDropdown/EventEditDropdown";
import { HoverEventContext } from "../../../../Config/HoverEvent";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails.jsx";

const EventLogItem = (props) => {
  const { event, currentState, intl, extraPlayInfo } = props;

  const { formatMessage } = intl;
  const [team, setTeam] = useState();
  const [player, setPlayer] = useState();
  const [staffRole, setStaffRole] = useState(null);
  const [eventKey, setEventKey] = useState();
  const [isPending, setIsPending] = useState();
  const [updatePlayer, setUpdatePlayer] = useState(true);
  const [showPlayerSelect, setShowPlayerSelect] = useState(false);
  const [canEdit, editFlow] = useCanEditDropdown(props);
  const [showEditDropdown, setShowEditDropdown] = useState(false);
  const { hoverStart, hoverEnd } = useContext(HoverEventContext);
  const { sport } = useContext(FixtureDetailsContext);

  const subkeyOverride = ["tipin"];

  const handleBibClick = () => {
    setShowPlayerSelect(!showPlayerSelect);
  };

  const handlePlayerChange = (person) => {
    setShowPlayerSelect(false);
    const sendAction = {
      action: "sendEvent",
      event: {
        ...event,
        status: "updated",
        personId: person.personId,
      },
      type: "custom",
    };
    sendEvent(sendAction, props);
  };

  const toggleEditDropdown = () => {
    setShowEditDropdown(!showEditDropdown);
  };

  const handleHover = (mouseInside) => {
    if (mouseInside) {
      hoverStart(event);
    } else {
      hoverEnd();
    }
  };

  useEffect(() => {
    const entity = currentState.entities.find((el) => el.entityId === event.entityId);
    setTeam(entity);
    setUpdatePlayer(true);
    let translationkey = "event." + event.eventType;

    if (sport === "handball" && event.eventType === "turnover") {
      translationkey = "event.ballFault";
    }

    if (event.subType) {
      translationkey = !ignoreSubkeyEventTypes.includes(event.eventType)
        ? translationkey + "." + event.subType
        : translationkey;
      translationkey = subkeyOverride.includes(event.subType) ? translationkey + "." + event.subType : translationkey;
    }

    const includeOptionFieldItem = includeOptionField.find((item) => item.eventType === event.eventType);
    if (includeOptionFieldItem) {
      const optionValue = event.options?.[includeOptionFieldItem.optionField];
      if (optionValue) {
        translationkey = translationkey + "." + optionValue;
      }
    }

    const customTranslation = eventsWithCustomTranslations[event.eventType];
    const customTranslationValue = extraPlayInfo[customTranslation];

    if (customTranslationValue) {
      translationkey = `${translationkey}.${customTranslationValue}`;
    }

    if (typeof event.success === "boolean") {
      translationkey = includeSuccessEventTypes.includes(event.eventType)
        ? translationkey + "." + event.success
        : translationkey;
    }

    setEventKey(translationkey);

    // Double Check pending status
    let pending = !!event.pending;
    if (currentState.acks && currentState.acks.findIndex((item) => item === event.eventId) > -1) {
      pending = false;
    }
    setIsPending(pending);
    // eslint-disable-next-line
  }, [currentState.updateLog]);

  useEffect(() => {
    if (team && updatePlayer) {
      setPlayer(team.persons.find((el) => el.personId === event.personId));

      if (sport === "handball") {
        setStaffRole(
          Object.keys(team.staffState).filter((role) => team.staffState[role].personId === event.personId)[0],
        );
      }

      setUpdatePlayer(false);
    }
    // eslint-disable-next-line
  }, [team, updatePlayer]);

  if (event.class === "clock" && event.eventType.includes("Card")) {
    return null;
  }

  return (
    <React.Fragment>
      {team && (
        <div
          className={"log-event pending-" + isPending}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          <span
            onClick={handleBibClick}
            className={"event-bib " + (team && lightOrDark(team.primaryColor))}
            style={
              team && {
                backgroundColor: team.primaryColor,
              }
            }
          >
            {player && player.bib} {!player && " "}
            {staffRole &&
              formatMessage({
                id: `role.abbrev.${staffRole}`,
                defaultMessage: `role.abbrev.${staffRole}`,
              })}{" "}
            {!staffRole && " "}
          </span>
          {showPlayerSelect && (
            <EventLogDropdown
              onOutsideClick={() => setShowPlayerSelect(false)}
              containerStyle={{
                top: "3vh",
              }}
              bridgeStyle={{
                width: "1.8vw",
              }}
            >
              <PlayerSelectEditor intl={intl} team={team} onPlayerSelect={handlePlayerChange} />
            </EventLogDropdown>
          )}
          <span onClick={toggleEditDropdown} className="event-type">
            {formatMessage({
              id: eventKey,
              defaultMessage: eventKey,
            })}
            <EventLogItemExtra event={event} eventStore={props.eventStore} />
          </span>
        </div>
      )}
      {!team && eventKey && (
        <div className="log-event" onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
          <span
            className="event-bib"
            style={{
              backgroundColor: "transparent",
            }}
          >
            {!player && " "}
          </span>
          <span onClick={toggleEditDropdown} className="event-type">
            {formatMessage({
              id: eventKey,
              defaultMessage: eventKey,
            })}
          </span>
        </div>
      )}
      {canEdit && showEditDropdown && (
        <EventEditDropdown
          editFlow={editFlow}
          onClose={() => {
            setShowEditDropdown(false);
          }}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default injectIntl(EventLogItem);
