export var FoulPlayer =
  // Foul Player Select
  {
    name: "foul-player",
    component: "MainPanel",
    layout: "main-panel large",
    mode: "visible",
    text: "foul.select-player",
    showOn: [{ state: "primary", value: "foul-player", operator: "is" }],
    allowFlag: true,
    allowCancel: true,
    allowCancelEvent: "main",
    showDetails: { events: ["main"] },
    children: [
      {
        name: "foul-player-select",
        component: "PlayerSelect",
        layout: "player-select",
        mode: "visible",
        startEvent: false,
        startPlay: false,
        showOn: [true],
        options: {
          currentEntity: true,
          showBench: true,
          showCoach: true,
        },
        actionGroups: [
          {
            actions: [
              {
                action: "clearData",
                value: ["options", "subType", "scores", "success"],
                type: "main",
              },
              { action: "clearEvent", value: "fouldrawn", type: "main" },
              { action: "nextState", value: "foul-type", type: "primary" },
            ],
            conditions: [],
          },
        ],
      },
    ],
  };
