import React from "react";
import { withRouter, Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import Alert from "../Base/Alert";

const TeamSetupControls = ({ match, intl, location, validationInfo }) => {
  const { formatMessage } = intl;
  const enoughStarters = Object.values(validationInfo).every(
    (entity) => entity.enoughStarters
  );
  const noDuplicates = Object.values(validationInfo).every(
    (entity) => entity.noDuplicates
  );
  const noBlanks = Object.values(validationInfo).every(
    (entity) => entity.noBlanks
  );
  const noUnconfirmedPlayers = Object.values(validationInfo).every(
    (entity) => entity.noUnconfirmed
  );
  const staffConfirmed = Object.values(validationInfo).every(
    (entity) => entity.staffConfirmed
  );

  const goalKeeperSelected = Object.values(validationInfo).every(
    (entity) => entity.goalKeeperSelected
  );

  const isNextBtnEnabled =
    enoughStarters &&
    noDuplicates &&
    noBlanks &&
    noUnconfirmedPlayers &&
    staffConfirmed &&
    goalKeeperSelected;
  return (
    <div className="team-setup-controls-container">
      <>
        {!staffConfirmed && (
          <Alert error>
            {formatMessage({
              id: "setup.staff.confirmed.error",
              defaultMessage: "Staff members not confirmed",
            })}
          </Alert>
        )}
        {!enoughStarters && (
          <Alert error>
            {formatMessage({
              id: "setup.starters.error",
              defaultMessage: "Insufficient starters selected",
            })}
          </Alert>
        )}
        {!noDuplicates && (
          <Alert error>
            {formatMessage({
              id: "setup.duplicates.error",
              defaultMessage: "Duplicate Numbers",
            })}
          </Alert>
        )}
        {!noBlanks && (
          <Alert error>
            {formatMessage({
              id: "setup.blanks.error",
              defaultMessage: "Blank Numbers",
            })}
          </Alert>
        )}

        {!goalKeeperSelected && (
          <Alert error>
            {formatMessage({
              id: "setup.goalkeeper.error",
              defaultMessage: "Please select a Goalkeeper",
            })}
          </Alert>
        )}

        {!noUnconfirmedPlayers && (
          <Alert warning>
            {formatMessage({
              id: "setup.confirmation.error",
              defaultMessage: "Waiting for Player Confirmation",
            })}
          </Alert>
        )}
      </>
      <Link
        to={{
          pathname: `/game/${match.params.fixtureId}`,
          search: location.search,
        }}
        className={`link btn btn-sm btn-success btn-next btn-lg enabled-${isNextBtnEnabled}`}
      >
        {formatMessage({
          id: "next",
          defaultMessage: "Next",
        })}{" "}
        <i className="fas fa-angle-right"></i>
      </Link>
    </div>
  );
};

export default injectIntl(withRouter(TeamSetupControls));
