export function checkGameStarted(props) {
  let status = false;
  if (props.currentState && props.currentState.period) {
    switch (props.currentState.period.periodId) {
      case 1:
        status =
          props.currentState.period.periodStatus !== "pending" ? true : false;
        break;
      default:
        status = true;
        break;
    }
  }
  return status;
}
