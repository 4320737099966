import { useState, useEffect } from "react";

export const useCanEditDropdown = (props) => {
  const { workFlow, event } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [editFlow, setEditFlow] = useState(null);

  useEffect(() => {
    if (workFlow?.modifyDropdown?.types) {
      workFlow.modifyDropdown.types.forEach((type) => {
        if (type.eventTypes.includes(event.eventType)) {
          setCanEdit(true);
          setEditFlow(type);
        }
      });
    }
  }, [workFlow?.modifyDropdown?.types, event.eventType]);

  return [canEdit, editFlow];
};
