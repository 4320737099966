import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";

import { calculateOnCourt } from "../../../../Base/Utilities/CalculateOnCourt";
import { clone } from "../../../../Base/Utilities/Object";
import { uniqueBy } from "../../../../Base/Utilities/CollectionUtilities";
import { getIsPlayerDisqualifiedBecauseOfFoulTypes } from "../../../../Base/Utilities/Player";
import { FixtureDetailsContext } from "../../../../../Config/FixtureDetails";
import { sendEvent } from "../../../../Base/Utilities/SendEvent";
import { useDoActions } from "../../../../Base/Hooks/doActions";
import Player from "../Player";

import "./Substitutions.scss";

const noopFunc = () => {};

const Substitutions = (props) => {
  const {
    currentState,
    done,
    eventStore,
    intl,
    panel,
    reportSubsValidity = () => {},
    setIsSubsVisible,
    team,
    updateState,
    // this functionality is not available when editing
    preSubs = [],
    togglePreSubs = noopFunc,
  } = props;

  const [persons, setPersons] = useState();
  const [initialPersonsActiveState, setInitialPersonsActiveState] = useState();
  const [currentEntity, setCurrentEntity] = useState(null);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const { fixtureProfile } = useContext(FixtureDetailsContext);
  const { formatMessage } = intl;

  const isNewSub = () => team !== undefined;

  const isSubActionValid = () => {
    const personStats =
      currentState.statistics[currentEntity.entityId]?.persons;
    const activePlayerCount = persons.filter((p) => p.active).length;
    const nonFouledOutCount = personStats
      ? persons.filter(
          (p) =>
            personStats[p.personId].foulsTotal <
              fixtureProfile.maxFoulsPersonal &&
            !getIsPlayerDisqualifiedBecauseOfFoulTypes(p.personId, eventStore)
        ).length
      : activePlayerCount;
    return (
      activePlayerCount >=
      Math.min(fixtureProfile.maximumPlayersOnCourt, nonFouledOutCount)
    );
  };

  const getEntityFromCurrentEvent = () => {
    const currentEntityEvent = panel.options.currentEntityEvent;
    if (panel.options.currentEntity === true) {
      return currentState.entities.find(
        (team) =>
          team.entityId === currentState.events[currentEntityEvent].entityId
      );
    } else if (panel.options.currentEntity === false) {
      return currentState.entities.find(
        (team) =>
          team.entityId !== currentState.events[currentEntityEvent].entityId
      );
    } else {
      return currentState.entities.filter((team) => team.entityId !== null)[
        panel.options.team
      ];
    }
  };

  const calculatePersonsOnCourt = (teamEntityId, excludeCurrentPlay) => {
    const entitiesOnCourt = [];
    const calculateOnCourtOption = panel.options
      ? panel.options.calculateOnCourt
      : true;
    if (excludeCurrentPlay) {
      // Build player lists before this event
      const currentPlayEventTime = currentState.events.edit.eventTime;
      entitiesOnCourt.push(
        ...calculateOnCourt(calculateOnCourtOption, props, currentPlayEventTime)
      );
    } else {
      // Build Player lists including this event
      entitiesOnCourt.push(...calculateOnCourt(calculateOnCourtOption, props));
    }
    return entitiesOnCourt.find((el) => el.entityId === teamEntityId).persons;
  };

  const initializeEntityState = (entity) => {
    setCurrentEntity(clone(entity));
    setPersons(clone(entity.persons));
  };

  const initializePersonsActiveState = (persons) => {
    setInitialPersonsActiveState(
      persons.reduce((soFar, curr) => {
        return {
          ...soFar,
          [curr.personId]: curr.desiredActiveState ?? curr.active,
        };
      }, {})
    );
  };

  useEffect(() => {
    currentEntity &&
      reportSubsValidity(currentEntity.entityId, isSubActionValid());
    // eslint-disable-next-line
  }, [persons, currentEntity]);

  useEffect(() => {
    if (persons?.length && !initialPersonsActiveState) {
      initializePersonsActiveState(persons);
    }
  }, [persons, initialPersonsActiveState]);

  const handlePlayerClick = (player) => {
    if (player) {
      const playerIndex = persons.findIndex(
        (el) => el.personId === player.personId
      );
      const newPersons = [...persons];
      const activePersonsCount = persons.filter(
        (person) => person.active === true
      ).length;

      if (newPersons[playerIndex].active) {
        newPersons[playerIndex].active = false;

        // sub in if someone is preselected
        if (preSubs.length) {
          const preSubbedPlayerIndex = newPersons.findIndex(
            (el) => el.personId === preSubs[0]
          );

          if (preSubbedPlayerIndex > -1) {
            // remove person from presubs
            togglePreSubs(newPersons[preSubbedPlayerIndex]);

            // sub in
            newPersons[preSubbedPlayerIndex].active = true;
          }
        }
      } else {
        if (activePersonsCount < fixtureProfile.maximumPlayersOnCourt) {
          newPersons[playerIndex].active = true;
        } else {
          // add person to presubs, if not there already
          togglePreSubs(newPersons[playerIndex]);
        }
      }

      setPersons(newPersons);
    }
  };

  const removeAll = () => {
    const newPersons = persons.map((person) => {
      return { ...person, active: false };
    });
    setPersons(newPersons);
  };

  const renderActivePlayerList = () => {
    const activeSorted = persons
      .filter((person) => !!person.active)
      .sort((a, b) => (parseInt(a.bib) < parseInt(b.bib) ? -1 : 1));

    const emptyRosterCount =
      fixtureProfile.maximumPlayersOnCourt - activeSorted.length;

    const result = activeSorted.map((player) => (
      <Player
        key={`player-${player.personId}`}
        entityId={currentEntity.entityId}
        player={player}
        teamColor={currentEntity.primaryColor}
        currentState={currentState}
        eventStore={eventStore}
        onClick={() => handlePlayerClick(player)}
        forcedActiveState={initialPersonsActiveState?.[player.personId]}
      />
    ));

    if (emptyRosterCount > 0) {
      for (let i = 0; i < emptyRosterCount; ++i) {
        result.push(<div className="substitutions__players__active__empty" />);
      }
    }
    return result;
  };

  useEffect(() => {
    const teamEntity = clone(
      isNewSub()
        ? currentState.entities.find((el) => el.entityId === team)
        : getEntityFromCurrentEvent()
    );
    if (!isNewSub()) {
      const personsOnCourt = calculatePersonsOnCourt(
        teamEntity.entityId,
        panel.options.excludePlay
      );
      teamEntity.persons = personsOnCourt;

      // setting initial colors
      const onCourtInPrevAction = calculatePersonsOnCourt(
        teamEntity.entityId,
        true
      );
      initializePersonsActiveState(onCourtInPrevAction);
    }
    initializeEntityState(teamEntity);
    // eslint-disable-next-line
  }, [team]);

  function processSubs(temp, current) {
    const clockParts = currentState.clock.displayTime.split(":");
    const clockTime =
      "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
    const currentTimestamp = new Date().toISOString();
    const playId = uuidv1();

    temp.map((player) => {
      let newPlayerState = current.find(
        (el) => el.personId === player.personId
      );
      if (player && newPlayerState) {
        if (player.active !== newPlayerState.active) {
          const subType = newPlayerState.active ? "in" : "out";
          const newEvent = {
            timestamp: currentTimestamp,
            eventTime: currentTimestamp,
            clock: clockTime,
            personId: player.personId,
            eventId: uuidv1(),
            eventType: "substitution",
            periodId: currentState.period.periodId,
            playId,
            subType,
            entityId: currentEntity.entityId,
          };
          const action = {
            action: "sendEvent",
            value: "new",
            type: "custom",
            event: newEvent,
          };
          sendEvent(action, props);
        }
      }
      return true;
    });
    if (currentState.insertStore && currentState.insertStore.length > 0) {
      updateState("processInserts", true);
    }
    setIsSubsVisible(null);
  }

  function editSubs(prev, current) {
    const currentEventPlayId = currentState.events.edit.playId;
    const deleteEvents = Object.values(currentState.events).filter(
      (ev) => ev.playId === currentEventPlayId
    );
    const uniques = uniqueBy(deleteEvents, "eventId");
    uniques.forEach((event) => {
      const deleteAction = {
        action: "sendEvent",
        event: { ...event, status: "deleted" },
        type: "custom",
      };
      sendEvent(deleteAction, props);
    });

    const currentTimestamp = new Date().toISOString();
    const initialEditEvent = currentState.events.edit;
    prev.map((player) => {
      let newPlayerState = current.find(
        (el) => el.personId === player.personId
      );
      if (player && newPlayerState) {
        if (player.active !== newPlayerState.active) {
          const subType = newPlayerState.active ? "in" : "out";
          const newEvent = {
            ...initialEditEvent,
            timestamp: currentTimestamp,
            personId: player.personId,
            eventId: uuidv1(),
            subType,
          };
          const action = {
            action: "sendEvent",
            value: "new",
            type: "custom",
            event: newEvent,
          };
          sendEvent(action, props);
        }
      }
      return true;
    });
  }

  const submitSubs = () => {
    if (isNewSub()) {
      const updatedEntities = clone(currentState.entities);
      const teamIndex = updatedEntities.findIndex(
        (el) => el.entityId === currentEntity.entityId
      );
      const onCourtInitially = updatedEntities[teamIndex].persons;
      processSubs(onCourtInitially, persons);
      updatedEntities[teamIndex].persons = persons.map((person) => ({
        ...person,
        desiredActiveState: person.active,
      }));
      updateState("entities", updatedEntities);
    } else {
      const onCourtInPrevAction = calculatePersonsOnCourt(
        currentEntity.entityId,
        true
      );
      editSubs(onCourtInPrevAction, persons);
    }
    setDoAction(true);
  };

  useEffect(() => {
    if (done) {
      submitSubs();
    }
    // eslint-disable-next-line
  }, [done]);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone, props]);

  return persons ? (
    <>
      <div className="substitutions">
        <div className="substitutions__players">
          <div className="substitutions__players__active">
            {renderActivePlayerList()}
          </div>
          <div className="substitutions__players__inactive">
            {persons
              .filter((person) => !person.active)
              .sort((a, b) => (parseInt(a.bib) < parseInt(b.bib) ? -1 : 1))
              .map((player) => (
                <Player
                  key={`player-${player.personId}`}
                  entityId={currentEntity.entityId}
                  player={player}
                  teamColor={currentEntity.primaryColor}
                  currentState={currentState}
                  eventStore={eventStore}
                  onClick={() => handlePlayerClick(player)}
                  isPreSubbed={preSubs.includes(player.personId)}
                  forcedActiveState={
                    initialPersonsActiveState?.[player.personId]
                  }
                />
              ))}
          </div>
        </div>
        <div className="substitutions__swap-icon" onClick={() => removeAll()}>
          <i className="fas fa-angle-double-down"></i>
        </div>
      </div>
      {!isNewSub() && (
        <div
          className={`match-button action sub-done-button ${
            isSubActionValid() ? "" : "disabled"
          }`}
          onClick={() => submitSubs()}
        >
          {formatMessage({
            id: "done",
            defaultMessage: "Done",
          })}
        </div>
      )}
    </>
  ) : null;
};

export default injectIntl(Substitutions);
