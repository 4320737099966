
import { injectIntl } from "react-intl";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import "./PlayerSelectEditor.scss"

const PlayerSelectEditor = (props) => {
  const { team, onPlayerSelect, intl } = props;
  const { formatMessage } = intl;

  return (
    <div className="player-select-editor-root">
      <div className="player-select-editor-players">
        {team?.persons?.map((player) => {
          return (
            <div
              key={player.bib}
              className={"player-select-editor-player " + (team && lightOrDark(team.primaryColor))}
              style={{
                  backgroundColor: team.primaryColor,
              }}
              onClick={() => {
                onPlayerSelect(player);
              }}
            >
              {player.bib}
            </div>
          )
        })}
      </div>
      <div
        className={"player-select-editor-team " + (team && lightOrDark(team.primaryColor))}
        style={{
          backgroundColor: team.primaryColor,
        }}
      >
        {formatMessage({
          id: "edit.assign.team",
          defaultMessage: "ASSIGN TO TEAM",
        })}
      </div>
    </div>
  )
}

export default injectIntl(PlayerSelectEditor);
