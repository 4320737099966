import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { injectIntl } from "react-intl";
import { useDoActions } from "../../Base/Hooks/doActions";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { lightOrDark } from "../../Base/Utilities/LightOrDark";
import TypeButton from "../ButtonPanel/TypeButton/TypeButton";
import MadeMissed from "../ScoreButtons/Basketball/MadeMissed";
import MadeMissedHandball from "../ScoreButtons/Handball/MadeMissedHandball";

import "./TeamButtons.scss";

const TeamButtons = (props) => {
  const { panel, currentState } = props;
  const [displayPosition, setPosition] = useState([-1000, -1000]);
  const [arrowPosition, setArrowPosition] = useState();
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const baseRef = useRef(null);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  const getDisplayValue = (team, button) => {
    if (!panel?.hideIfNoPossession) {
      return "block";
    }

    return team.hasPossession ? "block" : "none";
  };

  const teamButtons = useMemo(() => {
    if (panel && panel.children) {
      if (currentState.entities && currentState.entities.length > 0) {
        return currentState.entities.map((team, index) => (
          <div
            className={
              "team-buttons team-" +
              index +
              " " +
              lightOrDark(team.primaryColor)
            }
            style={{
              borderColor: team.primaryColor,
              display: getDisplayValue(team),
            }}
            key={team.entityId}
          >
            {panel.children.map((button, i) => (
              <React.Fragment key={i}>
                {(function () {
                  switch (button.component) {
                    case "MadeMissed":
                      return (
                        <MadeMissed
                          {...props}
                          panel={button}
                          success={button.options.success}
                          team={index}
                          style={{
                            background: team.primaryColor,
                          }}
                        />
                      );
                    case "MadeMissedHandball":
                      return (
                        <MadeMissedHandball
                          {...props}
                          panel={button}
                          success={button.options.success}
                          team={index}
                          style={{
                            background: team.primaryColor,
                          }}
                        />
                      );
                    case "TypeButton":
                      return (
                        <TypeButton
                          {...props}
                          panel={button}
                          team={index}
                          style={{
                            background: team.primaryColor,
                          }}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </React.Fragment>
            ))}
          </div>
        ));
      }
    }
    // eslint-disable-next-line
  }, [panel, props]);

  useEffect(() => {
    if (currentState.markerPos && baseRef.current) {
      const showArrowOnTop = currentState.markerPos.y < 50;
      let baseTop = currentState.markerPos.y;
      const baseLeft = currentState.markerPos.x;

      setPosition([baseTop + "%", baseLeft + "%"]);
      setArrowPosition(showArrowOnTop ? "arrowTop" : "arrowBottom");
    }
    // eslint-disable-next-line
  }, [currentState, props, baseRef.current]);

  if (displayPosition === null || !isVisible) {
    return null;
  }

  const floatingPanelContainer = document.querySelector(
    "#FloatingPanelContainer"
  );

  if (!floatingPanelContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={
        panel.layout +
        " " +
        arrowPosition +
        " enabled-" +
        isEnabled +
        " " +
        "team-buttons-" +
        (panel.hideIfNoPossession ? "oneteam" : "")
      }
      style={{ top: displayPosition[0], left: displayPosition[1] }}
      ref={baseRef}
    >
      <div className="disable-layer"></div>
      <i
        className="close-me fas fa-times"
        onClick={(e) => setDoAction(true)}
      ></i>
      {teamButtons}
    </div>,
    floatingPanelContainer
  );
};

export default injectIntl(TeamButtons);
