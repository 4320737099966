import { useMemo } from "react";

function useEntityDataByPanelName(panelName, currentState) {
  const data = useMemo(() => {
    const entityIndex = getEntityIndexFromPanelName(panelName);
    const entity = currentState?.entities?.[entityIndex];

    return {
      entity,
      entityIndex,
      players: entity?.persons ?? [],
      teamColor: entity?.primaryColor ?? "",
    };
  }, [panelName, currentState.entities]);

  return data;
}

export default useEntityDataByPanelName;

function getEntityIndexFromPanelName(name) {
  if (name.includes("team-0")) {
    return 0;
  }

  return 1;
}
