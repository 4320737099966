import React, { useCallback, useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { useDoActions } from "../../../Base/Hooks/doActions";
import { getEventTimeForUpdatedDeletedEvents, sendEvent } from "../../../Base/Utilities/SendEvent";

const stashPopAction = {
  actions: [{ action: "popStash", type: "main" }],
  conditions: [],
};

const Cancel = (props) => {
  const { updateState, intl, panel, events } = props;
  const { formatMessage } = intl;
  const [doAction, setDoAction] = useState(false);
  const cancelActions = [...(panel.cancelActions ? panel.cancelActions : []), stashPopAction];
  const [actionsDone] = useDoActions(panel, props, doAction, cancelActions);

  const getPanelStateLevels = () => {
    let levels = new Set();
    panel.showOn.forEach(({ state }) => {
      if (["primary", "secondary"].includes(state)) {
        levels.add(state);
      }
    });
    return levels;
  };

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  const doCancel = useCallback(() => {
    if (panel.allowCancelEvent) {
      const originalEvent = events[panel.allowCancelEvent];
      let cancelEvent = JSON.parse(JSON.stringify(originalEvent));

      if (cancelEvent.eventType) {
        cancelEvent.status = "deleted";
        cancelEvent.eventTime = getEventTimeForUpdatedDeletedEvents(cancelEvent);

        if (originalEvent) {
          cancelEvent.eventTime = getEventTimeForUpdatedDeletedEvents(cancelEvent);
        }

        let action = {
          action: "sendEvent",
          event: cancelEvent,
          type: "custom",
        };
        sendEvent(action, props);
      }
    }
    setDoAction(true);
    getPanelStateLevels().forEach((level) => {
      updateState(level, null);
    });
    updateState("editState", null);
    if (panel.doFullCancel) {
      updateState("modify", null);
    }
  }, [updateState, panel, events, props]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.code === "Escape") {
        doCancel();
      }
    },
    [doCancel],
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <div className="cancel" onClick={doCancel}>
      <span>
        {formatMessage({
          id: "cancel",
          defaultMessage: "cancel",
        })}
      </span>
      <i className="fas fa-times"></i>
    </div>
  );
};

export default injectIntl(Cancel);
