const POSSIBLE_COACH_OPTIONS = ["COACH", "COACH_HEAD"];

export const getMaximumPlayersActiveForSport = (fixtureProfile, sport) => {
  if (sport === "basketball") return fixtureProfile.maximumPlayersOnCourt;
  if (sport === "hockey") return fixtureProfile.maximumPlayersOnField;
  if (sport === "ice3") return fixtureProfile.maximumPlayersOnIce;
  if (sport === "handball") return fixtureProfile.maximumPlayersOnCourt;
};

const skipEnoughStartersForSports = ["handball"];

export const getValidationObject = (
  entity,
  fixtureProfile = null,
  sport = "basketball"
) => {
  if (!fixtureProfile) {
    console.debug("No fixture profile provided for entity validation!");
    return null;
  }

  const starterCount = entity.persons.filter(
    (player) => player.starter === true
  ).length;
  const duplicateCount = entity.persons.filter(
    (player) => player.duplicate === true
  ).length;
  const blankCount = entity.persons.filter(
    (player) => player.bib.trim().length === 0 && player.playing === true
  ).length;
  const unconfirmedCount = entity.persons.filter(
    (player) => player.playing === true && !player.confirmed
  ).length;
  const isCoachAvailable =
    entity.staff.filter((person) => person.role.includes("COACH")).length > 0;
  const isMainCoachSelected =
    entity.staff.filter(
      (person) => person.active && POSSIBLE_COACH_OPTIONS.includes(person.role)
    ).length > 0;
  const noStaffSelected = Object.values(entity.staffState).every(
    (p) => p.personId === ""
  );
  const noInactiveStaffMember = Object.values(entity.staffState).every(
    (p) => p.personId === "" || p.active === true
  );

  const isStaffConfirmed =
    noStaffSelected || (isCoachAvailable && isMainCoachSelected && noInactiveStaffMember);

  const isGoalKeeperSelected = entity.persons.some(
    (person) => person.position === "G" && person.playing === true
  );

  return {
    enoughStarters: skipEnoughStartersForSports.includes(sport)
      ? true
      : starterCount === getMaximumPlayersActiveForSport(fixtureProfile, sport),
    noDuplicates: duplicateCount === 0,
    noBlanks: blankCount === 0,
    noUnconfirmed: unconfirmedCount === 0,
    staffConfirmed: isStaffConfirmed,
    goalKeeperSelected: sport === "handball" ? isGoalKeeperSelected : true,
  };
};

export const isEntityValidToCompete = (entity, fixtureProfile, sport) => {
  const validationObj = getValidationObject(entity, fixtureProfile, sport);
  return validationObj && Object.values(validationObj).every((prop) => !!prop);
};
