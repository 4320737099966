export const StealPlayerSelect = {
  name: "steal",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "steal.select-player",
  showOn: [{ state: "primary", value: "steal-player", operator: "is" }],
  allowBack: {
    state: "primary",
    value: "technical-fault-type",
    event: "main",
    save: false,
  },
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["steal"] },
  children: [
    {
      name: "steal-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "steal", type: "eventType" },
            { action: "clearData", value: ["subType"], type: "main" },
            { action: "copyEvent", value: "steal", type: "to" },
            { action: "copyEvent", value: "fault", type: "from" },
            {
              action: "nextState",
              value: "technical-fault-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "confirm-later-steal-player",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "confirmLater",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "steal", type: "eventType" },
            { action: "clearData", value: ["subType"], type: "main" },
            {
              action: "saveData",
              value: { currentEntity: false },
              type: "entityId",
            },
            { action: "copyEvent", value: "steal", type: "to" },

            { action: "saveData", type: "flagged", value: true },

            { action: "copyEvent", value: "fault", type: "from" },

            {
              action: "nextState",
              value: "technical-fault-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      startPlay: false,
    },
  ],
};
