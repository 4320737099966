import { useRef } from "react";
import { injectIntl } from "react-intl";
import { useOnClickOutside } from "../../../Base/Hooks/useOnClickOutside";
import { sendEvent } from "../../../Base/Utilities/SendEvent";
import EventLogDropdown from "../EventLogDropdown/EventLogDropdown";

import "./EventEditDropdown.scss";

const EventEditDropdown = (props) => {
  const { editFlow, event, intl, onClose } = props;
  const { formatMessage } = intl;

  const deleteEvent = () => {
    let action = {
      action: "sendEvent",
      event: {
        ...event,
        status: "deleted",
      },
      type: "custom",
    };
    sendEvent(action, props);
  };

  const changeEventSubType = (newSubType) => {
    let action = {
      action: "sendEvent",
      event: {
        ...event,
        status: "updated",
        subType: newSubType,
      },
      type: "custom",
    };
    sendEvent(action, props);
  };

  const changeEventOptions = (newOption) => {
    let action = {
      action: "sendEvent",
      event: {
        ...event,
        status: "updated",
        options: { [newOption]: true },
      },
      type: "custom",
    };
    sendEvent(action, props);
  };

  const handleItemClick = (elemKey) => {
    if (editFlow.edit?.type === "customFn") {
      editFlow.edit?.customFn && editFlow.edit?.customFn(props, elemKey);
    } else if (editFlow.edit?.type === "delete") {
      if (elemKey === "true") {
        deleteEvent();
      }
    } else if (editFlow.edit?.type === "subType") {
      changeEventSubType(elemKey);
    } else if (editFlow.edit?.type === "options") {
      changeEventOptions(elemKey);
    }
  };

  return (
    <EventLogDropdown
      onOutsideClick={onClose}
      contentStyle={{
        width: '100%',
      }}
    >
      <div className="event-edit-dropdown-list">
        {Object.entries(editFlow.edit?.options).map(([key, value]) => (
          <span
            key={key}
            onClick={() => {
              handleItemClick(key);
              onClose && onClose();
            }}
          >
            {formatMessage({
              id: value,
              defaultMessage: value,
            })}
          </span>
        ))}
      </div>
    </EventLogDropdown>
  );
};

export default injectIntl(EventEditDropdown);
