import React from "react";
import { injectIntl } from "react-intl";
import Player from "./Player";

import "./Players.scss";
import { v1 as uuidv1 } from "uuid";
import { getEventTime, sendEvent } from "../../../Base/Utilities/SendEvent";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark.js";

function Players(props) {
  const { players, entity, teamColor, intl, currentState } = props;
  const { formatMessage } = intl;

  const { goalkeepers } = players.reduce(
    (soFar, current) => {
      if (current.position === "G") {
        soFar.goalkeepers.push(current);
      } else {
        soFar.rest.push(current);
      }

      return soFar;
    },
    { goalkeepers: [], rest: [] }
  );

  const isOpenNet = goalkeepers.every((player) => !player.active);

  const sendPlayerEvent = (player, playId) => {
    const timestamp = new Date();
    const clockParts = currentState.clock.displayTime.split(":");
    const msg = {
      class: "sport",
      eventType: "goalKeeperChange",
      entityId: entity.entityId,
      personId: player ? player.personId : undefined,
      options: player ? undefined : {"emptyNet": true},
      eventId: uuidv1(),
      playId: playId,
      periodId: currentState.period.periodId,
      timestamp: timestamp.toISOString(),
      eventTime: getEventTime(),
      clock:
        "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S",
      status: "added",
    };

    const subAction = {
      action: "sendEvent",
      event: msg,
      type: "custom",
    };

    sendEvent(subAction, props);

    if (currentState.insertStore && currentState.insertStore.length > 0) {
      props.updateState("processInserts", true);
    }
  };

  const onPlayerClickHandler = (player) => {
    const playId = uuidv1();

    if (player.position === "G" && !player.active) {
      sendPlayerEvent(player, playId);
    }

    goalkeepers.forEach((goalkeeper) => {
      if (
        goalkeeper.personId !== player.personId &&
        goalkeeper.active === true
      ) {
        goalkeeper.active = false;
      }
    });
  };

  const onOpenNetClickHandler = () => {
    const playId = uuidv1();

    sendPlayerEvent(undefined, playId);

    goalkeepers.forEach((goalkeeper) => {
      if (goalkeeper.active === true) {
        goalkeeper.active = false;
      }
    });
  };

  const getPlayersList = (players) => {
    return (
      <div className="handball-players-layout">
        {players.map((player) => {
          return (
            <Player
              key={player.personId}
              entity={entity}
              player={player}
              teamColor={teamColor}
              onPlayerClickHandler={onPlayerClickHandler}
              {...props}
            >
              {player.bib}
            </Player>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <h1 className="handball-players-title">
        {formatMessage({
          id: "setActiveGK",
          defaultMessage: "Set active GK",
        })}
      </h1>
      {getPlayersList(goalkeepers)}
      <div
        className={`handball-open-net ${ isOpenNet && lightOrDark(teamColor) }`}
        style={{
          backgroundColor: isOpenNet ? teamColor : "#3B444C",
        }}
        onClick={onOpenNetClickHandler}
      >
        {formatMessage({
          id: "openNet",
          defaultMessage: "Open net",
        })}
      </div>
    </div>
  );
}

export default injectIntl(Players);
